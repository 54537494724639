<template>
  <transport-wrapper>
    <template v-slot:map>
      <l-map
        v-if="!yMap"
        :zoom="zoom"
        :center="customPanelCoordsIfAvailable ? customPanelCoordsIfAvailable : [GET_STOP.lat, GET_STOP.lon]"
        ref="mapTransport"
        @update:zoom="zoomUpdated"
      >
        <l-tile-layer
          :url="url"
        />
        <template
          v-if="BUS_STATION"
        >
          <l-marker
            v-for="(busStation, index) in BUS_STATION"
            :key="index"
            :lat-lng="[busStation.lat, busStation.lon]"
            @click="clickStation(busStation, $event)"
          >
            <l-icon
              :icon-url="(busStation.id === GET_STOP.id) ? '' : busStationIcon"
              :icon-size="(busStation.id === station.id) || (busStation.id === GET_STOP.id) ? bigIconSize : iconSize"
              :icon-anchor="(busStation.id === station.id) || (busStation.id === GET_STOP.id) ? iconRedAnchor : iconAnchor"
            />
          </l-marker>
          <l-moving-marker
            v-for="(bus, index) in BUSES"
            :key="`${index} - ${bus.extBusId}`"
            :lat-lng="[bus.lat, bus.lon]"
            :duration="10000"
            @click="clickBus(bus)"
          >
            <l-icon
              :icon-size="iconSizeBus"
              :class-name="iconBusClass"
            >
              <div class="name-route">
                {{ bus.routeNo }}
              </div>
              <img class="icon-bus__img" src="@/assets/img/bus.svg">
            </l-icon>
          </l-moving-marker>
          <l-polyline
            :lat-lngs="directRoutes"
            :color="polyline.color"
            :weight="6"
          />
          <l-polyline
              v-if="customPanelCoordsIfAvailable && nearStopCoordsIfAvailable"
              :lat-lngs="[customPanelCoordsIfAvailable, nearStopCoordsIfAvailable]"
              :color="'red'"
              :dashArray="'20, 20'"
              :dashOffset="'20'"
              :weight="6"
          />
          <l-marker
            class="l-marker"
            :lat-lng="customPanelCoordsIfAvailable ? customPanelCoordsIfAvailable : [GET_STOP.lat, GET_STOP.lon]"
            @click="showStop"
          >
            <l-icon
              :icon-url="panelCoords ? panelPointIcon : busPointIconRed"
              :icon-size="bigIconSize"
              :icon-anchor="[38, 60]"
              :zIndexOffset="10"
            >
            </l-icon>
          </l-marker>
        </template>
      </l-map>
      <yandex-map
        v-else
        :coords="center"
      >
      </yandex-map>
    </template>

    <template v-slot:control>
      <component
        v-if="currentComponent"
        :is="currentComponent"
        :properties="currentProperties"
        :bus="bus"
        @get-direct="getDirect"
        @switch-bus-station="switchBusStation"
        @show-track="showTrack"
      >
      </component>
      <transport-card
        v-else
        @go-bus-station="goBusStation"
        @toggle-map="toggleMap"
      />
    </template>
  </transport-wrapper>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import L from "leaflet/dist/leaflet";

import TransportWrapper from "@/components/Wrappers/TransportWrapper";
import TransportCard from "@/components/Parts/TransportCard";
import BusStop from "@/components/Parts/BusStop";
import TimeTable from "@/components/Parts/TimeTable";
import RoutesTimetable from "@/components/Parts/RoutesTimetable";
import BusPanel from "@/components/Parts/BusPanel";
import {socket, subscribeRoutes} from "@/api/socket.js";

let zoomSize = {
  18: [48, 48],
  16: [28, 28],
  14: [18, 18],
  12: [0, 0],
}

export default {
  name: "TransportScreen",
  components: {
    TransportWrapper,
    TransportCard,
    BusStop,
    TimeTable,
    RoutesTimetable,
    BusPanel
  },

  props: {
    route: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      zoom: 14,
      center: [61.0042, 69.0019],
      map: null,
      icon: L.icon({
        iconSize: [96, 120],
        iconUrl: require("@/assets/img/busPoint.svg"),
        iconAnchor: [38, 107],
        iconClass: "l-marker"
      }),
      iconRedAnchor: [38, 107],
      iconAnchor: [0, 0],
      iconSize: [18, 18],
      iconSizeBus: [62, 41],
      bigIconSize: [96, 120],
      station: {},
      bus: null,
      busPointIconRed: require("@/assets/img/busPointRed.svg"),
      busStationIcon: require("@/assets/img/busStation.svg"),
      busPointIcon: require("@/assets/img/busPoint.svg"),
      panelPointIcon: require("@/assets/img/panelPoint.svg"),
      currentPath: null,
      polyline: {
        color: "#0B83FF",
      },
      directRoutes: [],
      routesId: 0,
      chosenStationData: [],
      idBusStation: 0,
      coordinateActiveMarker: {},
      yMap: false,
      activeRoutes: [],
      iconBusClass: ""
    }
  },

  mounted() {
    this.GET_SCHEDULE_STATION_ROUTES({
      routeId: this?.GET_STOP?.direct_routes[0]?.id || this?.GET_STOP?.backward_routes[0]?.id,
      stopId: this?.GET_STOP?.id
    });

    this.coordinateActiveMarker = {
      lat: this.GET_STOP.lat,
      lon: this.GET_STOP.lon
    };

    // this.iconSize = this.zoom;
  },

  methods: {
    ...mapActions([
      "GET_GEOMETRY_ROUTES",
      "GET_SCHEDULE_STATION_ROUTES",
      "GET_LIST_ROUTES_PASSING_BUS_STOP",
      "GET_LIST_ROUTES_WITH_DIRECTION_DIRECT",
      "GET_LIST_ROUTES_WITH_DIRECTION_BACKWARD"
    ]),

    ...mapMutations([
      "CLEAR_ROUTES"
    ]),

    clickStation(busStation) {
      this.stationMapPanTo(busStation);
      this.GET_LIST_ROUTES_PASSING_BUS_STOP(busStation.id);

      if (this.currentPath !== `/transport/time-table/${busStation.id}`) {

        this.CLEAR_ROUTES();

        // отписываемся от предыдущей остановки
        if (this.station) {
          socket.unsubscribe(`STOP.${this.station.id}`);
        }

        if (this.CURRENT_ROUTES.length > 0) {
          this.CURRENT_ROUTES.forEach(el => {
            socket.unsubscribe(`ROUTE.${el.id}`);
          })
        }

        if (this.activeRoutes.length > 0) {
          this.activeRoutes.forEach(el => {
            socket.unsubscribe(`ROUTE.${el.id}`);
          })
        }

        this.$router.push(`/transport/time-table/${busStation.id}`);
        this.station = busStation;

          (async () => {
            let channel = socket.subscribe(`STOP.${busStation.id}`);
            socket.transmit('load', `STOP.${busStation.id}`);

            for await (let data of channel) {
              this.chosenStationData = data;
              this.activeRoutes = Object.keys(data.routes).map(i => data.routes[i]);
              this.activeRoutes.forEach(el => {
                subscribeRoutes(el.id);
              });
            }
          })();
      }
    },

    async clickBus(bus) {
      if (this.currentPath !== `/transport/bus-panel/${bus.extBusId}`) {
        this.$router.push(`/transport/bus-panel/${bus.extBusId}`);
        this.bus = bus;

        this.showTrackOnMap(this.bus.routeId);
        await this.GET_SCHEDULE_STATION_ROUTES({routeId: this.bus.routeId, stopId: this.GET_STOP.id});
        await this.GET_GEOMETRY_ROUTES({routeId: this.bus.routeId, stopId: this.GET_STOP.id});

        this.directRoutes = this.LIST_ROUTES_DIRECTION;
      }
    },

    showTrack(route) {
      this.showTrackOnMap(route.id);
    },

    async showTrackOnMap(routeId) {
      // direction 0 прямое направление
      // direction 1 обратное направление
      const direct = 0;
      const backward = 1;

      await this.GET_LIST_ROUTES_WITH_DIRECTION_DIRECT({routeId: routeId, direction: direct});
      await this.GET_LIST_ROUTES_WITH_DIRECTION_BACKWARD({routeId: routeId, direction: backward});
      this.directRoutes = this.LIST_ROUTES_DIRECTION;
    },

    zoomUpdated(zoom) {
      console.log(zoom, 'zoom');
      if (zoom >= 16) {
        this.iconBusClass = "icon-bus"
      } else if (zoom >= 14) {
        this.iconBusClass = "icon-bus--mini"
      } else {
        this.iconBusClass = "icon-bus--hide"
      }

      if(zoomSize[zoom] !== undefined) {
        this.iconSize = zoomSize[zoom];
      }
    },

    getDirect(item) {
      this.routesId = item.id;
      let route = item.direct.concat(item.backward);
      this.directRoutes = route.map(el => [el.Lat, el.Lon]);
      this.GET_SCHEDULE_STATION_ROUTES({routeId: this.routesId, stopId: this.GET_STOP.id});
    },

    goBusStation(busStationId) {
      this.idBusStation = busStationId;
    },

    switchBusStation(busStation) {
      this.clickStation(busStation);
      this.station = busStation;
      this.$refs.mapTransport.mapObject.panTo([busStation.lat, busStation.lon]);
    },

    toggleMap(isShow) {
      if (isShow) {
        this.yMap = true;
      } else {
        this.yMap = false;
      }
    },

    stationMapPanTo(station) {
      this.$refs.mapTransport.mapObject.panTo([station.lat, station.lon])
    },

    showStop() {
      this.$router.push('/transport/routes-timetable');
    }
  },

  computed: {
    ...mapGetters([
      "BUS_STATION",
      "BUSES",
      "GET_STOP",
      "GEOMETRY_ROUTES",
      "CURRENT_ROUTES",
      "SCHEDULE_STATION_ROUTES",
      "LIST_ROUTES_DIRECTION",
      "LIST_ROUTES_PASSING_BUS_STOP",
      "ROUTES",
      "panelCoords",
      "nearStopCoords",
    ]),

    customPanelCoordsIfAvailable() {
      if (this.panelCoords) {
        return this.panelCoords.split(',');
      }

      return null;
    },

    nearStopCoordsIfAvailable() {
      if (this.nearStopCoords) {
        return this.nearStopCoords.split(',');
      }

      return null;
    },

    currentComponent: function() {
      return this.$route.params.screen;
    },

    currentProperties: function() {
      if ((this.currentComponent === 'routes-timetable') || (this.currentComponent === 'bus-panel')) {
        const data = {
          getStop: this.GET_STOP,
          scheduleStationRoutes: this.SCHEDULE_STATION_ROUTES
        }
        return data;
      }

      if (this.currentComponent === 'time-table') {
        const data = {
          currentStation: this.station,
          currentStationData: this.chosenStationData
        }
        return data;
      }
      return '';
    },
  },

  watch: {
    $route(newState) {
      this.currentPath = newState.path;
    },

    directRoutes(newValue) {
      this.directRoutes = newValue;
    },

    idBusStation(newValue) {
      if (newValue) {
        this.BUS_STATION.map(el => {
          if (el.id === newValue) {
            this.station = el;
            this.stationMapPanTo(el);
          }
        })
      }
    },

    route(newValue) {
      this.showTrack(newValue);
    }
  }
}
</script>

<style lang="scss">
.icon-bus {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border-radius: 100px;
  font-size: 24px;
  font-weight: bold;
  line-height: 36px;
  color: #0b83ff;

  .name-route {
    padding: 10px;
  }

  &__img {
    width: 28px;
    height: 28px;
    margin-right: 10px;
    background-color: #0b83ff;
    border: 1px solid #fff;
    border-radius: 50%;
  }

  &--mini {
    width: 41px;
    padding: 7px;

    .name-route {
      display: none;
    }
  }

  &--hide {
    display: none;
  }
}

.ymap-container {
  height: 681px;
  overflow: hidden;
}
</style>
